/*eslint sort-keys: ["error", "asc", {natural: true}]*/
import Experiment from 'Utils/experiments/experiment';

export const VARIANTS = {
	CITY_PAGE_OLD: 'Control',
	CITY_PAGE_REVAMPED: 'Treatment',
	CONTROL: 'Control',
	DISABLE_GOOGLE_PAY: 'Control',
	ENABLE_GOOGLE_PAY: 'Treatment',
	HIDE_AUDIO_GUIDE: 'HIDE',
	HIDE_COMBO_UPSELL_SCREEN: 'Control',
	HIDE_FULFILLED_BY_HEADOUT: 'Control',
	HIDE_HOHO_V2: 'Control',
	HIDE_MULTI_CITIES: 'Control',
	HIDE_NEW_EXPERIENCE_PAGE_DESCRIPTORS: 'Control',
	HIDE_REVAMP_SELECT_PAGE: 'Control',
	HIDE_REVIEW_MEDIA: 'Control',
	LOAD_USER_RECORDING_SCRIPT: 'LOAD',
	NEGLECT_USER_RECORDING_SCRIPT: 'NEGLECT',
	PROXIMITY_CONTROL: 'CONTROL',
	PROXIMITY_TREATMENT: 'TREATMENT',
	SHOW_AUDIO_GUIDE: 'SHOW',
	SHOW_COMBO_UPSELL_SCREEN: 'Treatment',
	SHOW_FULFILLED_BY_HEADOUT: 'Treatment',
	SHOW_HOHO_V2: 'Treatment',
	SHOW_MULTI_CITIES: 'Treatment',
	SHOW_NEW_COLLECTION_CARDS: 'Treatment',
	SHOW_NEW_EXPERIENCE_PAGE_DESCRIPTORS: 'Treatment',
	SHOW_OLD_COLLECTION_CARDS: 'Control',
	SHOW_REVAMP_SELECT_PAGE: 'Treatment',
	SHOW_REVIEW_MEDIA: 'Treatment',
	TREATMENT: 'Treatment',
	TREATMENT_A: 'Treatment_A',
	TREATMENT_B: 'Treatment_B',
};

const HOLIDAY_BANNER_VISIBILITY = 'Holiday ProductBanner Visibility';
const MB_FULLFILLED_BY_HEADOUT = 'Fulfilled By Headout Banner Experiment';
const MULTI_CITIES_CONFIRMATION = 'Cross-City Recommendations Experiment';
const PROXIMITY_CONF_PAGE_EXPERIMENT = 'Confirmation Page Proximity Experiment';
const REVAMP_SELECT_PAGE = 'Revamped Select Page - 2';
const REVIEW_MEDIA_VISIBILITY = 'Review Media Experiment';
const SELECT_SCREEN_PAX = 'Dweb Pax Selection on Select Page 2024';
const SELECT_REVAMP = 'Select Page Revamp 2.5';
const HOHO_REVAMP = 'HOHO Select Page';
const HOP_ON_HOP_OFF_V2 = 'HOHO Mweb Select Page v2';
const COLLECTION_CARDS_REVAMP_DWEB = 'Collection Cards Revamp';
const REMOVE_BOOKING_FEES_EXPERIMENT = 'Remove Booking Fees';
const CROSS_SELL_EXPERIMENT = 'Cross Sell Experiment';
const BROADWAY_SVG_EXPERIMENT = 'Broadway SVG Section Selection v2';
const SEARCH_EXPERIMENT = 'Cached Search Results Experiment';
const DE_IT_CTA_COPY = 'DE IT CTA Copy v2';
const TOFU_CITY_PAGE = 'Tofu City Page Revamp';

export const EXPERIMENT_NAMES = {
	BROADWAY_SVG_EXPERIMENT,
	COLLECTION_CARDS_REVAMP_DWEB,
	CROSS_SELL_EXPERIMENT,
	DE_IT_CTA_COPY,
	HOHO_REVAMP,
	HOLIDAY_BANNER_VISIBILITY,
	HOP_ON_HOP_OFF_V2,
	MB_FULLFILLED_BY_HEADOUT,
	MULTI_CITIES_CONFIRMATION,
	PROXIMITY_CONF_PAGE_EXPERIMENT,
	REMOVE_BOOKING_FEES_EXPERIMENT,
	REVAMP_SELECT_PAGE,
	REVIEW_MEDIA_VISIBILITY,
	SEARCH_EXPERIMENT,
	SELECT_REVAMP,
	SELECT_SCREEN_PAX,
	TOFU_CITY_PAGE,
};

export const EXPERIMENTS = {
	[BROADWAY_SVG_EXPERIMENT]: new Experiment(
		BROADWAY_SVG_EXPERIMENT,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[100, 0],
	),
	[COLLECTION_CARDS_REVAMP_DWEB]: new Experiment(
		EXPERIMENT_NAMES.COLLECTION_CARDS_REVAMP_DWEB,
		[
			VARIANTS.SHOW_OLD_COLLECTION_CARDS,
			VARIANTS.SHOW_NEW_COLLECTION_CARDS,
		],
		[50, 50],
	),
	[CROSS_SELL_EXPERIMENT]: new Experiment(
		CROSS_SELL_EXPERIMENT,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[10, 90],
	),
	[DE_IT_CTA_COPY]: new Experiment(
		DE_IT_CTA_COPY,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[50, 50],
	),
	[HOP_ON_HOP_OFF_V2]: new Experiment(
		HOP_ON_HOP_OFF_V2,
		[VARIANTS.SHOW_HOHO_V2, VARIANTS.HIDE_HOHO_V2],
		[50, 50],
	),
	[MB_FULLFILLED_BY_HEADOUT]: new Experiment(
		MB_FULLFILLED_BY_HEADOUT,
		[
			VARIANTS.HIDE_FULFILLED_BY_HEADOUT,
			VARIANTS.SHOW_FULFILLED_BY_HEADOUT,
		],
		[50, 50],
	),
	[MULTI_CITIES_CONFIRMATION]: new Experiment(
		MULTI_CITIES_CONFIRMATION,
		[VARIANTS.SHOW_MULTI_CITIES, VARIANTS.HIDE_MULTI_CITIES],
		[50, 50],
	),
	[PROXIMITY_CONF_PAGE_EXPERIMENT]: new Experiment(
		PROXIMITY_CONF_PAGE_EXPERIMENT,
		[VARIANTS.PROXIMITY_TREATMENT, VARIANTS.PROXIMITY_CONTROL],
		[50, 50],
	),
	[REMOVE_BOOKING_FEES_EXPERIMENT]: new Experiment(
		REMOVE_BOOKING_FEES_EXPERIMENT,
		[VARIANTS.CONTROL, VARIANTS.TREATMENT],
		[50, 50],
	),
	[REVAMP_SELECT_PAGE]: new Experiment(
		REVAMP_SELECT_PAGE,
		[VARIANTS.SHOW_REVAMP_SELECT_PAGE, VARIANTS.HIDE_REVAMP_SELECT_PAGE],
		[0, 100],
	),
	[REVIEW_MEDIA_VISIBILITY]: new Experiment(
		REVIEW_MEDIA_VISIBILITY,
		[VARIANTS.SHOW_REVIEW_MEDIA, VARIANTS.HIDE_REVIEW_MEDIA],
		[100, 0],
	),
	[SEARCH_EXPERIMENT]: new Experiment(
		EXPERIMENT_NAMES.SEARCH_EXPERIMENT,
		[VARIANTS.CONTROL, VARIANTS.TREATMENT],
		[50, 50],
	),
	[SELECT_REVAMP]: new Experiment(
		EXPERIMENT_NAMES.SELECT_REVAMP,
		[VARIANTS.CONTROL, VARIANTS.TREATMENT],
		[100, 0],
	),
	[SELECT_SCREEN_PAX]: new Experiment(
		SELECT_SCREEN_PAX,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[0, 100],
	),
	[TOFU_CITY_PAGE]: new Experiment(
		TOFU_CITY_PAGE,
		[VARIANTS.CITY_PAGE_REVAMPED, VARIANTS.CITY_PAGE_OLD],
		[50, 50],
	),
	USER_RECORDING_SCRIPT_RANDOMIZATION: new Experiment(
		'user_recording_script_randomization',
		[
			VARIANTS.NEGLECT_USER_RECORDING_SCRIPT,
			VARIANTS.LOAD_USER_RECORDING_SCRIPT,
		],
		[98, 2],
	),
};
